<template>
  <div class="line-graph" :class="{ skeleton }">
    <canvas :height="height || 250" ref="canvasRef"></canvas>
  </div>
</template>

<script>
import {defineComponent, onMounted, ref} from "@vue/composition-api";
import mixin from "@/scripts/mixin";
import definitions from "@/scripts/definitions";
import store from "@/scripts/store";

function Component(initialize) {
  this.name = "componentLineGraph";
  this.initialize = initialize;
}

export default defineComponent({
  mixins: [mixin],
  props: {
    data: Object,
    height: Number,
    skeleton: Boolean,
  },
  setup(props) {
    const component = new Component(() => {
    });

    const canvasRef = ref();

    onMounted(() => {
      store.dispatch("appendScript", {
        src: "//cdnjs.cloudflare.com/ajax/libs/Chart.js/3.8.0/chart.min.js",
        onEveryLoad() {
          props.data.datasets = props.data.datasets.map(ds => ({
            ...ds,
            borderColor: ds.borderColor || "#5e72e4",
            fill: false,
            cubicInterpolationMode: "monotone",
          }));

          const data = {
            labels: props.data.labels,
            datasets: props.data.datasets
          };

          const options = {
            responsive: true,
            maintainAspectRatio: false,
            animation: {
              duration: 0
            },
            plugins: {
              legend: {
                display: data.datasets.length > 1,
                align: "start",
                position: "bottom",
                labels: {
                  fontcolor: "#ffffff",
                  font: {
                    family: definitions.font,
                    size: 14,
                  },
                }
              }
            },
            interaction: {
              intersect: false,
            },
            scales: {
              x: {
                display: true,
                title: {
                  display: false
                },
                grid: {
                  display: false
                }
              },
              y: {
                display: true,
                title: {
                  display: false,
                },
              },
            }
          };

          new window.Chart(canvasRef.value, {type: "line", data, options});
        }
      });
    });

    return {component, canvasRef};
  }
});
</script>

<style lang="scss" scoped>
.line-graph {
  &.skeleton {
    @include skeleton;

    canvas {
      visibility: hidden;
    }
  }
}
</style>
<template>
  <div class="admin-stats-utm">
    <div class="header">
      <div class="search">
        <div class="categories" :style="{width: computedCategoriesWidth}">
          <div class="row">
            <div class="col source">
              <select class="form-control" v-model="state.args.utmSource" @change="searchByUtmCategory('source')" :title="state.args.utmSource" :disabled="!computedLoaded">
                <option value="">소스 선택</option>
                <option :value="s" v-for="(s, idx) in state.categories.data.utmSources" :key="idx">{{ s }}</option>
              </select>
              <span class="badge balloon bg-default color-white" v-if="!state.args.utmSource">UTM 검색</span>
            </div>
            <template v-if="state.args.utmSource">
              <div class="col">
                <select class="form-control" v-model="state.args.utmMedium" @change="searchByUtmCategory('medium')" :title="state.args.utmMedium" :disabled="!computedLoaded">
                  <option value="">미디엄 선택</option>
                  <option :value="m" v-for="(m, idx) in state.categories.data.utmMediums" :key="idx">{{ m }}</option>
                </select>
              </div>
              <div class="col" v-if="state.args.utmMedium">
                <select class="form-control" v-model="state.args.utmCampaign" @change="searchByUtmCategory('campaign')" :title="state.args.utmCampaign" :disabled="!computedLoaded">
                  <option value="">캠페인 선택</option>
                  <option :value="c" v-for="(c, idx) in state.categories.data.utmCampaigns" :key="idx">{{ c }}</option>
                </select>
              </div>
            </template>
          </div>
        </div>
        <div :id="`${component.name}Calendar`" class="calendar">
          <button class="btn wrapper" title="기간 검색" @click="state.calendar.visible = !state.calendar.visible" :disabled="!computedLoaded">
            <i class="fa fa-calendar-o"></i>
          </button>
          <div :id="`${component.name}CalendarDates`" class="dates" v-show="state.calendar.visible">
            <div class="row">
              <div class="col from">
                <label :for="`${component.name}StartDate`">시작일</label>
                <Date :id="`${component.name}StartDate`" componentNameSuffix="StartDate" class="font-xs" placeholder="ex) 20221001" :value.sync="state.args.startDate" :func="searchByDates" :disabled="!computedLoaded"/>
              </div>
              <div class="col to">
                <label :for="`${component.name}EndDate`">종료일</label>
                <Date :id="`${component.name}EndDate`" componentNameSuffix="EndDate" class="font-xs" placeholder="ex) 20221013" :value.sync="state.args.endDate" :func="searchByDates" :disabled="!computedLoaded"/>
              </div>
            </div>
            <span class="fold pointer" title="닫기" @click="state.calendar.visible = false">&times;</span>
          </div>
          <span class="badge balloon bg-default color-white" v-if="computedDates">{{ computedDates }}</span>
        </div>
        <div class="projects">
          <select class="form-control" v-model="state.args.urlPath" @change="searchByUrlPath()" :title="state.args.utmSource" :disabled="!computedLoaded">
            <option value="">프로젝트 선택</option>
            <option :value="p.urlPath" v-for="(p, idx) in state.projects.data" :key="idx">({{ p.category }}) {{ p.title }}</option>
          </select>
        </div>
        <div class="refresh">
          <button class="btn wrapper" title="새로고침" @click="init()" :disabled="!computedLoaded">
            <i class="fa fa-refresh"></i>
          </button>
        </div>
      </div>
    </div>
    <div class="graphs" v-if="state.amounts.loaded">
      <div class="row">
        <div class="col-lg-4">
          <div class="subject">
            <span class="text">일별 데이터 수</span>
            <div class="float-right">(단위: 건)</div>
          </div>
          <LineGraph :data="state.amounts.data.total"/>
        </div>
        <div class="col-lg-4">
          <div class="subject">
            <span class="text">일별 방문자 수</span>
            <div class="float-right">(단위: 명)</div>
          </div>
          <LineGraph :data="state.amounts.data.user"/>
        </div>
        <div class="col-lg-4">
          <div class="subject">
            <span class="text">일별 평균 체류 시간</span>
            <div class="float-right">(단위: 시간)</div>
          </div>
          <LineGraph :data="state.amounts.data.stay"/>
        </div>
      </div>
    </div>
    <div class="sections" :class="{skeleton: !state.counts.loaded}">
      <div class="subject">
        <span class="text">주요 인터랙션</span>
      </div>
      <div class="row">
        <div class="col-xl-3 col-md-12" v-for="(s, name, idx) in state.sections" :key="idx">
          <div class="card card-stats">
            <button class="btn card-body" title="클릭 시 필터" @click="filterNames(s.names, s.type, s.httpMethod)" :disabled="!computedLoaded">
              <div class="row">
                <div class="col">
                  <div class="title font-xs card-title text-uppercase text-muted mb-0">
                    <span>{{ s.title }}</span>
                  </div>
                  <span class="value font-weight-bold mb-0">{{ s.count }}</span>
                </div>
                <div class="col-auto">
                  <div class="icon icon-shape text-white rounded-circle shadow" :class="s.color">
                    <span>{{ s.emoji }}</span>
                  </div>
                </div>
              </div>
              <span class="tail mt-3 mb-0 font-xs" :class="s.tail.class">{{ s.tail.text }}</span>
            </button>
          </div>
        </div>
      </div>
    </div>
    <div class="tracks">
      <div class="subject">
        <span class="text">상세 인터랙션</span>
        <div class="badges">
          <div class="badge bg-secondary" v-for="(s, name, idx) in state.sections" :key="idx" v-show="isVisibleBadge(s) > 0">
            <span>{{ s.emoji }}</span>
            <b> {{ s.title }}</b>
            <span class="remove" title="삭제" @click="removeNamesArg()">&times;</span>
          </div>
        </div>
        <div class="side pt-1 font-xs float-right">
          <span>사용자 {{ $lib.getNumberFormat(state.counts.data.user) }}명 / 데이터 {{ $lib.getNumberFormat(state.counts.data.total) }}건</span>
        </div>
      </div>
      <template v-if="state.tracks.data.length">
        <ul class="tight">
          <li :class="{skeleton: state.tracks.pageIdx === 0 ? !state.tracks.loaded : t.skeleton}" v-for="(t, idx) in state.tracks.data" :key="idx + 1">
            <div class="texts mb-2 font-sm">
              <b>{{ t.memberName }}</b>
              <span class="date" title="최초 접속 일시"> {{ t.firstDate }}</span>
              <span class="stay" title="체류 시간" v-if="t.staySeconds">(+ {{ $lib.getTimeFormat(t.staySeconds) }})</span>
              <div class="badges">
                <div class="badge bg-secondary" title="클릭 시 필터" @click="filterNames(s.names, s.type, s.httpMethod)" v-for="(s, name, idx) in state.sections" :key="idx" v-show="getTrackBadgeCount(t, name) > 0">
                  <span>{{ s.emoji }}</span>
                  <b> {{ s.title }}</b>
                  <span v-if="getTrackBadgeCount(t, name) > 1"> &times; {{ getTrackBadgeCount(t, name) }}</span>
                </div>
              </div>
            </div>
            <div class="categories" v-if="t.utmSource">
              <span class="badge">UTM Tag</span>
              <span title="UTM Source" class="utm pointer" @click="searchByUtmCategories(t.utmSource)">{{ t.utmSource }}</span>
              <template v-if="t.utmMedium">
                <i class="fa fa-angle-right"></i>
                <span title="UTM Medium" class="utm pointer" @click="searchByUtmCategories(t.utmSource, t.utmMedium)">{{ t.utmMedium }}</span>
                <template v-if="t.utmCampaign">
                  <i class="fa fa-angle-right"></i>
                  <span title="UTM Campaign" class="utm pointer" @click="searchByUtmCategories(t.utmSource, t.utmMedium, t.utmCampaign)">{{ t.utmCampaign }}</span>
                  <template v-if="t.utmContent">
                    <i class="fa fa-angle-right"></i>
                    <span title="UTM Content" class="utm">{{ t.utmContent }}</span>
                    <template v-if="t.utmTerm">
                      <i class="fa fa-angle-right"></i>
                      <span title="UTM Term" class="utm">{{ t.utmTerm }}</span>
                    </template>
                  </template>
                </template>
              </template>
            </div>
            <div class="progress" title="클릭 시 상세 조회" @click="openDetail(t)">
              <div class="progress-bar progress-bar-animated" :class="t.classes" role="progressbar" :style="{width: t.width + '%'}">
                <b>{{ $lib.getNumberFormat(t.cnt) }}건</b>
                <span>의 인터랙션</span>
              </div>
            </div>
          </li>
        </ul>
        <div class="more" v-if="state.tracks.data.length < state.counts.data.user">
          <button class="btn btn-secondary" @click="setTracks(true)" :disabled="!state.tracks.loaded">더 보기</button>
        </div>
      </template>
      <div class="no-data" v-else-if="state.tracks.loaded">
        <NoData/>
      </div>
    </div>
  </div>
</template>

<script>
import {computed, onMounted, onUnmounted, reactive, watch} from "@vue/composition-api";
import mixin from "@/scripts/mixin";
import http from "@/scripts/http";
import lib from "@/scripts/lib";
import store from "@/scripts/store";
import router from "@/scripts/router";
import Date from "@/components/Date";
import NoData from "@/components/NoData";
import sections from "@/texts/admin/statTrackSections.json";
import LineGraph from "@/components/LineGraph";

function Component(initialize) {
  this.name = "pageAdminStatsUtm";
  this.initialize = initialize;
}

export default {
  components: {LineGraph, NoData, Date},
  mixins: [mixin],
  setup() {
    const component = new Component(() => {
      init();
    });

    const state = reactive({
      args: {
        names: "",
        type: "",
        httpMethod: "",
        urlPath: "",
        utmSource: "",
        utmMedium: "",
        utmCampaign: "",
        startDate: "",
        endDate: "",
      },
      projects: {
        loaded: false,
        data: []
      },
      categories: {
        loaded: false,
        data: {
          utmSources: [],
          utmMediums: [],
          utmCampaigns: []
        }
      },
      tracks: {
        pageIdx: 0,
        colorIdx: 0,
        lastCnt: 0,
        memberNum: 0,
        cntPerPage: 100,
        loaded: false,
        data: []
      },
      counts: {
        loaded: false,
        data: {
          total: 0,
          user: 0,
          investorApply: 0,
          projectJoin: 0,
          memberJoin: 0,
          memberLogin: 0,
        }
      },
      amounts: {
        loaded: false,
        data: {
          total: {},
          user: {},
          stay: {},
        }
      },
      calendar: {
        visible: false
      },
      sections: sections
    });

    const colors = ["danger", "warning", "info", "green", "primary", "default"];
    // const colors = ["primary"];

    const computedDates = computed(() => {
      const arr = [];
      let startDate;

      if (state.args.startDate || state.args.endDate) {
        if (state.args.startDate) {
          startDate = lib.getDateFormat(state.args.startDate, "yy.MM.dd");
          arr.push(startDate);
        }

        arr.push("~");

        if (state.args.endDate) {
          let endDate = lib.getDateFormat(state.args.endDate, "yy.MM.dd");

          if (startDate) {
            endDate = endDate.replace(startDate.substr(0, 6), "").replace(startDate.substr(0, 3), "");
          }

          arr.push(endDate);
        }
      }

      return arr.join("");
    });

    const computedLoaded = computed(() => {
      return state.categories.loaded && state.tracks.loaded;
    });

    const computedCategoriesWidth = computed(() => {
      const unit = 110;
      let result = unit;

      if (state.args.utmSource) {
        result += unit;

        if (state.args.utmMedium) {
          result += unit;
        }
      }

      return result + "px";
    });

    const isVisibleBadge = (badge) => {
      if (state.args.names) {
        return state.args.names === badge.names.join(",");
      }

      return false;
    };

    const getTrackBadgeCount = (track, badgeName) => {
      return track[`${badgeName}Cnt`];
    };

    const removeNamesArg = () => {
      const query = lib.getRenewed(router.app.$route.query);
      delete query.names;
      delete query.type;
      delete query.httpMethod;
      pushToRouter(query);
    };

    const pushToRouter = (query) => {
      if (JSON.stringify(router.app.$route.query) === JSON.stringify(query)) {
        return;
      }

      router.push({query});
    };

    const onClick = (e) => {
      const calendarElem = lib.getElementById(lib.getSelfAndParents(e.target), `${component.name}Calendar`);
      const calendarDatesElem = lib.getElementById(lib.getSelfAndParents(e.target), `${component.name}CalendarDates`);

      if (!calendarElem && !calendarDatesElem) {
        state.calendar.visible = false;
      }
    };

    const openDetail = (track) => {
      store.commit("openModal", {
        name: "Track",
        params: {
          chainCode: track.chainCode,
          memberName: track.memberName,
          firstDate: track.firstDate,
          lastDate: track.lastDate,
          staySeconds: track.staySeconds,
        }
      });
    };

    const filterNames = (names, type, httpMethod) => {
      const query = lib.getRenewed(router.app.$route.query);
      Array.isArray(names) && names.length ? query.names = names.join(",") : delete query.names;
      type ? query.type = type : delete query.type;
      httpMethod ? query.httpMethod = httpMethod : delete query.httpMethod;
      pushToRouter(query);
    };

    const searchByDates = () => {
      const query = lib.getRenewed(router.app.$route.query);

      if (state.args.endDate) {
        query.endDate = state.args.endDate;
      } else {
        delete query.endDate;
      }

      if (state.args.startDate) {
        query.startDate = state.args.startDate;
      } else {
        delete query.startDate;
      }

      pushToRouter(query);
    };

    const searchByUrlPath = () => {
      const query = lib.getRenewed(router.app.$route.query);

      if (state.args.urlPath) {
        query.urlPath = state.args.urlPath;
      } else {
        delete query.urlPath;
      }

      pushToRouter(query);
    };

    const searchByUtmCategory = (category) => {
      const query = lib.getRenewed(router.app.$route.query);

      switch (category) {
        case "source": {
          if (state.args.utmSource) {
            query.utmSource = state.args.utmSource;
          } else {
            delete query.utmSource;
          }

          delete query.utmMedium;
          delete query.utmCampaign;
          break;
        }

        case "medium": {
          query.utmMedium = state.args.utmMedium;
          delete query.utmCampaign;
          break;
        }

        case "campaign": {
          query.utmCampaign = state.args.utmCampaign;
          break;
        }
      }

      pushToRouter(query);
    };

    const searchByUtmCategories = (source, medium, campaign) => {
      if (source) {
        const query = lib.getRenewed(router.app.$route.query);
        query.utmSource = source;

        if (medium) {
          query.utmMedium = medium;

          if (campaign) {
            query.utmCampaign = campaign;
          }
        }

        pushToRouter(query);
      }
    };

    const getOptimizedArgs = () => {
      const obj = {};

      for (let i in state.args) {
        if (state.args[i]) {
          if (["utmSource", "utmMedium", "utmCampaign"].includes(i)) {
            obj[i] = window.encodeURIComponent(state.args[i]);
          } else {
            obj[i] = state.args[i];
          }
        }
      }

      return obj;
    };

    const setProjects = (args = {}) => {
      state.projects.loaded = false;
      http.get("/api/admin/stats/track/projects", args).then(({data}) => {
        state.projects.loaded = true;
        state.projects.data = data.body;
      });
    };

    const setCategories = (args = {}) => {
      state.categories.loaded = false;
      http.get("/api/admin/stats/track/categories", args).then((res) => {
        const params = res.config.params;
        const values = res.data.body.map(d => d.value);
        state.categories.loaded = true;

        if (!Object.keys(params).length) {
          state.categories.data.utmSources = values;

          if (state.args.utmSource && state.categories.data.utmSources.includes(state.args.utmSource)) {
            args.utmSource = state.args.utmSource;
            setCategories(args);
          }
        } else if (params.utmMedium) {
          state.categories.data.utmCampaigns = values;
        } else if (params.utmSource) {
          state.categories.data.utmMediums = values;

          if (state.args.utmMedium && state.categories.data.utmMediums.includes(state.args.utmMedium)) {
            args.utmMedium = state.args.utmMedium;
            setCategories(args);
          }
        }
      });
    };

    const setTracks = (more) => {
      const args = getOptimizedArgs();

      if (more) {
        state.tracks.pageIdx += 1;
      } else {
        state.tracks.pageIdx = 0;
        state.tracks.colorIdx = 0;
        state.tracks.lastCnt = 0;
        state.tracks.memberNum = 0;
      }

      args.pageSkipCnt = state.tracks.cntPerPage * state.tracks.pageIdx;
      args.pageTakeCnt = state.tracks.cntPerPage;

      state.tracks.loaded = false;
      http.get("/api/admin/stats/tracks", args).then(({data}) => {
        state.tracks.loaded = true;
        const tracks = data.body;

        const getColorIdx = (i, cnt) => {
          if (Number(i) !== 0 && state.tracks.lastCnt !== cnt) {
            state.tracks.colorIdx += 1;
          }

          state.tracks.colorIdx = state.tracks.colorIdx % colors.length;
          return state.tracks.colorIdx;
        };

        if (!more) {
          state.tracks.data = [];
        }

        for (let i in tracks) {
          let width = 0;
          const cnt = tracks[i].cnt;
          const chainCode = tracks[i].chainCode;
          const memberName = tracks[i].memberName || ("익명의 사용자" + (++state.tracks.memberNum));
          const firstDate = tracks[i].firstDate;
          const lastDate = tracks[i].lastDate;
          const staySeconds = tracks[i].staySeconds;
          const utmSource = tracks[i].utmSource;
          const utmMedium = tracks[i].utmMedium;
          const utmCampaign = tracks[i].utmCampaign;
          const utmContent = tracks[i].utmContent;
          const utmTerm = tracks[i].utmTerm;
          const memberJoinCnt = tracks[i].memberJoinCnt;
          const memberLoginCnt = tracks[i].memberLoginCnt;
          const projectJoinCnt = tracks[i].projectJoinCnt;
          const investorApplyCnt = tracks[i].investorApplyCnt;
          const classes = ["bg-" + colors[getColorIdx(i, cnt)]];

          state.tracks.lastCnt = cnt;

          if (tracks[i].cnt <= 10) {
            width = tracks[i].cnt * 5;
          } else {
            width = 50 + tracks[i].cnt * 0.5;
          }

          if (width > 100) {
            width = 100;
          }

          state.tracks.data.push({
            cnt,
            chainCode,
            width,
            firstDate,
            lastDate,
            staySeconds,
            memberName,
            utmSource,
            utmMedium,
            utmCampaign,
            utmContent,
            utmTerm,
            memberJoinCnt,
            memberLoginCnt,
            projectJoinCnt,
            investorApplyCnt,
            classes,
          });
        }
      });
    };

    const setCounts = () => {
      const args = getOptimizedArgs();

      state.counts.loaded = false;
      http.get("/api/admin/stats/track/counts", args).then(({data}) => {
        state.counts.loaded = true;
        state.counts.data = data.body;

        for (let i in state.sections) {
          state.sections[i].count = lib.getNumberFormat(state.counts.data[i] || 0) + "건";
        }
      });
    };

    const setArgs = () => {
      state.args.names = router.app.$route.query.names || "";
      state.args.type = router.app.$route.query.type || "";
      state.args.httpMethod = router.app.$route.query.httpMethod || "";
      state.args.utmSource = router.app.$route.query.utmSource || "";
      state.args.utmMedium = router.app.$route.query.utmMedium || "";
      state.args.utmCampaign = router.app.$route.query.utmCampaign || "";
      state.args.endDate = router.app.$route.query.endDate || "";
      state.args.startDate = router.app.$route.query.startDate || "";
      state.args.urlPath = router.app.$route.query.urlPath || "";
    };

    const setAmounts = () => {
      const args = getOptimizedArgs();

      state.amounts.loaded = false;
      http.get("/api/admin/stats/track/amounts", args).then(({data}) => {
        state.amounts.loaded = true;
        state.amounts.data.user = [];
        state.amounts.data.total = [];
        state.amounts.data.stay = [];

        state.amounts.data.user = {
          labels: data.body.map(item => item.createdDay),
          datasets: [
            {
              data: data.body.map(item => item.user),
              borderColor: "#5e72e4",
            },
          ]
        };

        state.amounts.data.total = {
          labels: data.body.map(item => item.createdDay),
          datasets: [
            {
              data: data.body.map(item => item.total),
              borderColor: "#11cdef",
            },
          ]
        };

        state.amounts.data.stay = {
          labels: data.body.map(item => item.createdDay),
          datasets: [
            {
              data: data.body.map(item => (item.stay / 3600 / item.user).toFixed(1)),
              borderColor: "#2dce89",
            },
          ]
        };
      });
    };

    const init = () => {
      for (let i = 0; i < 15; i += 1) {
        state.tracks.data.push({
          memberName: "Wait a moment",
          cnt: 0,
          firstDate: lib.getDateFormat(new window.Date(), "yyyy-MM-dd HH:mm:ss"),
          lastDate: lib.getDateFormat(new window.Date(), "yyyy-MM-dd HH:mm:ss"),
          staySeconds: 0,
          width: 5,
          utmSource: "Wait a moment",
          utmMedium: "Wait a moment",
          utmCampaign: "Wait a moment",
          skeleton: true,
        });
      }

      setArgs();
      setProjects();
      setCategories();
      setTracks();
      setCounts();
      setAmounts();
    };

    onMounted(() => {
      document.body.addEventListener("click", onClick);
    });

    onUnmounted(() => {
      document.body.removeEventListener("click", onClick);
    });

    watch(() => [
      router.app.$route.query.utmSource, router.app.$route.query.utmMedium, router.app.$route.query.utmCampaign,
      router.app.$route.query.endDate, router.app.$route.query.startDate, router.app.$route.query.urlPath,
      router.app.$route.query.names, router.app.$route.query.type, router.app.$route.query.httpMethod,
    ], (next, prev) => {
      if (JSON.stringify(next) !== JSON.stringify(prev)) {
        init();
        window.scrollTo({top: 0, left: 0, behavior: "smooth"});
      }
    });

    return {
      component,
      state,
      init,
      colors,
      computedDates,
      computedLoaded,
      computedCategoriesWidth,
      isVisibleBadge,
      getTrackBadgeCount,
      removeNamesArg,
      searchByDates,
      searchByUrlPath,
      searchByUtmCategory,
      searchByUtmCategories,
      filterNames,
      setCategories,
      setProjects,
      setTracks,
      openDetail,
    };
  }
};
</script>

<style lang="scss" scoped>
.admin-stats-utm {
  position: relative;
  padding-top: $px15;

  .balloon {
    position: absolute;
    top: 0;
    right: 0;
    border-radius: $px4 $px4 0 $px4;
  }

  .subject {
    font-size: $px14;
    margin-bottom: $px25;

    > .text {
      font-weight: 600;
    }
  }

  > .header {
    position: absolute;
    top: $px-55;
    right: 0;

    > .search {
      position: relative;
      padding-right: $px265;

      select {
        height: $px43;
      }

      i {
        font-size: $px14;
      }

      .categories > .row > .col {
        padding-left: 0;

        > select {
          font-size: $px12;
        }

        &.source {
          position: relative;

          .balloon {
            margin-right: $px129;
            margin-top: $px-15;
          }
        }
      }

      .calendar {
        position: absolute;
        top: 0;
        right: $px211;
        height: $px43;
        z-index: 1;

        .wrapper {
          width: $px42;
          height: 100%;
          text-align: center;
          cursor: pointer;
          border: $px1 solid #dee2e6;
          padding: $px8 0;
          border-radius: $px4;
          position: relative;
          margin: 0;

          i {
            margin: 0;
          }
        }

        .dates {
          background: #fff;
          border: $px1 solid #eee;
          border-radius: $px4 0 $px4 $px4;
          position: absolute;
          top: 100%;
          right: $px32;
          margin-top: $px-10;
          width: $px330;
          padding: $px10 $px18 $px18 $px18;

          .row .col {
            label {
              font-size: $px12;
              margin-bottom: $px7;
            }

            &.from {
              position: relative;

              &:after {
                content: "~";
                position: absolute;
                bottom: 0;
                right: 0;
                margin-bottom: $px10;
                margin-right: $px-4;
              }
            }
          }

          > .fold {
            position: absolute;
            top: 0;
            right: 0;
            font-size: $px12;
            padding: $px10;
            transition: color 0.25s;

            &:hover {
              color: #000;
            }
          }
        }

        .balloon {
          margin-top: $px-15;
          margin-right: $px32;
          display: inline-block;
        }
      }

      .projects {
        position: absolute;
        top: 0;
        right: $px54;
        width: $px145;

        > select {
          font-size: $px12;
        }
      }

      .refresh {
        position: absolute;
        top: 0;
        right: 0;
        height: $px43;
        z-index: 1;

        .wrapper {
          width: $px42;
          height: 100%;
          text-align: center;
          cursor: pointer;
          border: $px1 solid #dee2e6;
          padding: $px8 0;
          border-radius: $px4;
          position: relative;
          margin: 0;

          i {
            margin: 0;
          }
        }
      }
    }
  }

  .graphs {
    margin-bottom: $px40;
  }

  .sections {
    .card .card-body {
      text-align: left;
      box-shadow: none;

      .row .icon {
        padding: $px10;
        width: $px42;
        height: $px42;
      }

      &:not([disabled]):hover {
        outline: $px1 solid $colorPurple;
      }
    }

    &.skeleton {
      .card .card-body .row {
        .title span, .value, .icon {
          @include skeleton;
        }
      }

      .tail {
        @include skeleton;
      }
    }
  }

  .tracks {
    padding-top: $px10;

    .badges {
      display: inline-block;
      margin-left: $px9;

      .badge {
        margin-right: $px6;
        border-radius: $px4;
        outline: $px1 solid #eee;
      }
    }

    > .subject {
      > .text {
        vertical-align: middle;
      }

      > .badges {
        vertical-align: middle;

        .badge {
          position: relative;
          padding-right: $px22;

          .remove {
            position: absolute;
            cursor: pointer;
            top: 0;
            right: $px1;
            height: 100%;
            width: $px15;
            border-left: $px1 solid #eee;
            text-align: center;
            padding: $px5 0;
            transition: background-color 0.25s;
            color: rgba(0, 0, 0, 0.6);

            &:hover {
              background-color: #f7f7f7;
              color: inherit;
            }
          }
        }
      }
    }

    > ul > li {
      position: relative;
      margin-bottom: $px25;

      .texts {
        .date {
          font-size: $px12;
          margin-right: $px2;
          margin-left: $px3;
        }

        .stay {
          font-size: $px12;
        }

        .badges {
          .badge {
            cursor: pointer;

            &.detail {
              cursor: pointer;
            }
          }
        }
      }

      .categories {
        position: absolute;
        top: $px2;
        right: 0;
        font-size: $px10;
        padding-bottom: $px3;

        > .badge {
          background: $colorBackground;
          border: $px1 solid #eee;
          font-size: $px10;
          font-weight: normal;
          margin-right: $px6;
          padding: $px5;
          vertical-align: middle;
          border-radius: $px4;
        }

        .utm {
          &:hover {
            color: #000;
          }
        }

        i {
          margin: 0 $px5;
        }
      }

      > .progress {
        cursor: pointer;
        height: auto;
        margin-bottom: 0;

        > .progress-bar {
          display: block;
          font-weight: normal;
          padding: $px12 $px7;
          min-width: $px90;
          text-align: left;
          font-size: $px12;
          transition: filter 0.25s;
        }

        &:hover > .progress-bar {
          filter: brightness(0.9);
        }
      }

      &.skeleton {
        > .texts {
          > span, > b {
            @include skeleton;
          }

          > .badges > .badge {
            @include skeleton;
          }
        }

        .categories {
          @include skeleton;

          > .badge {
            visibility: hidden;
          }
        }

        > .progress {
          @include skeleton;

          > .progress-bar {
            @include skeleton;
            color: transparent;
            width: 0 !important;
          }
        }
      }
    }

    .more {
      padding: $px10 0 $px25 0;
      text-align: center;
    }

    .no-data {
      background: #f8f9fe;
      padding: $px50 $px10;
    }
  }

  @media(max-width: 991px) {
    > .header {
      position: static;

      > .search {
        padding-right: 0;
        padding-bottom: $px30;

        .categories {
          width: 100% !important;

          .row {
            display: block;

            > .col {
              padding-left: $px15;
              margin-top: $px8;

              &.source .balloon {
                right: auto;
                left: 0;
                margin-right: 0;
              }
            }
          }
        }

        .calendar {
          position: relative;
          width: 100%;
          margin: $px15 0;
          top: auto;
          right: auto;

          .btn {
            width: 100%;
          }

          .wrapper .balloon {
            right: auto;
            left: 0;
            margin-right: 0;
            margin-left: $px-16;
          }

          .dates {
            right: $px-30;
            margin-top: $px-1;
            width: calc(100% + 60px);
          }
        }

        .projects {
          position: static;
          width: 100%;
        }
      }
    }

    .tracks > ul > li .categories {
      position: static;
      padding: 0 0 $px7 0;
    }
  }
}
</style>